import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { ConfigService, LocatorService } from "./../service";
import { Globals } from "./../../globals";
import { AppContextService } from "./../service";
import { AuthenticationService } from "src/app/features/auth-module";
import { MenuItem, MenuItemCommandEvent } from "primeng/api";
import { Constants } from "../model/constants/constants";
import { Guid } from "../model/shared";

@Injectable()
export abstract class BaseComponent {
  protected destroyed$: Subject<void> = new Subject<void>();
  globals: Globals = LocatorService.injector.get(Globals);
  public windowSizeChangedSubscription: Subscription | undefined;
  public windowResizedSubscription: Subscription | undefined;
  public orientationChangedSubscription: Subscription | undefined;
  public windowMouseClickSubscription: Subscription | undefined;
  public windowStorageChangedSubscription: Subscription | undefined;
  public authChangedSubscription: Subscription | undefined;
  public navigationSubscription: Subscription | undefined;
  public formStatusChangeSubscription: Subscription | undefined;
  public receivedMessageSubscription: Subscription | undefined;

  // Used to show the sign in dialog
  public isShowSigninDialog: boolean = false;

  // Used to show the sign in dialog
  public isShowSwitchEnvironmentDialog: boolean = false;

  public loadCompleted = new BehaviorSubject<boolean>(false);
  public loadError = new BehaviorSubject<any>(null);
  public loadCompletedSubscription: Subscription | undefined;

  public CMId = Guid.newGuid();
  public delaySendMsgTimer: any;

  public delayLoadInitTimer: any;
  public delayGetMenuTimer: any;
  public delaySetMenuTimer: any;
  public delaySetDivTimer: any;
  public showComponent: boolean = false;
  public showMenuItems: boolean = false;
  browserMenuItems: MenuItem[] = [];
  mobileMenuItems: MenuItem[] = [];
  sideMenuItems: MenuItem[] = [];
  cmpntMenuItems: MenuItem[] = [];
  browserMenu: MenuItem[] = [];
  mobileMenu: MenuItem[] = [];
  sideMenu: MenuItem[] = [];
  cmpntMenu: MenuItem[] = [];
  previousMenu: string = "";
  public IsSideMenuView: boolean = false;
  public IsTopMenuView: boolean = false;
  public showClose: boolean = false;
  private queryParams: any;
  private authenticationService: AuthenticationService;
  private configService: ConfigService;
  constructor(public appCtx: AppContextService) {
    this.queryParams = {
      queryParams: this.appCtx.route.snapshot.queryParams,
      queryParamMap: this.appCtx.route.snapshot.queryParamMap,
      fragment: this.appCtx.route.snapshot.fragment
    };
    this.authenticationService = new AuthenticationService(this.appCtx);
    this.configService = new ConfigService(this.appCtx);
  }
  get authSvc() {
    return this.authenticationService;
  }
  get cfgSvc() {
    return this.configService;
  }
  get sharedSvc() {
    return this.appCtx.sharedSvc;
  }
  get confirmSvc() {
    return this.appCtx.confirmSvc;
  }
  get domSvc() {
    return this.appCtx.domSvc;
  }
  get utilSvc() {
    return this.appCtx.utilSvc;
  }
  get IsMobileView() {
    return this.appCtx.IsMobileView;
  }
  get IsPortraitView() {
    return this.appCtx.IsPortraitView;
  }
  get CurrentYear() {
    return new Date().getFullYear();
  }
  get isSA() {
    return this.appCtx.IsRole(Constants.UserRoleType.APCOSystem, Constants.UserRole.SystemAdministrator);
  }
  get isAdmin() {
    return this.appCtx.IsRole(Constants.UserRoleType.APCOSystem, Constants.UserRole.Administrator);
  }
  get isMemberAdmin() {
    return this.appCtx.IsRole(Constants.UserRoleType.MemberSystem, Constants.UserRole.SystemAdministrator);
  }
  get isMemberUser() {
    return this.appCtx.IsRole(Constants.UserRoleType.MemberSystem, Constants.UserRole.User);
  }
  get isSupportUser() {
    return this.appCtx.IsRole(Constants.UserRoleType.APCOSystem, Constants.UserRole.SupportUser);
  }
  get isUser() {
    return this.appCtx.IsRole(Constants.UserRoleType.APCOSystem, Constants.UserRole.User);
  }
  get currrentEnvironmentCode() {
    return this.appCtx.currrentEnvironmentCode;
  }
  get baseURL() {
    let port = window.location.port;
    let url = window.location.protocol + "//" + window.location.hostname;
    if (port) {
      url = window.location.protocol + "//" + window.location.hostname + ":" + port;
    }
    return url;
  }
  onDestroy(): void {
    try {
      this.destroyed$.next(undefined);
      this.destroyed$.complete();
    } catch (ex) {
      console.error(ex);
    }
  }
  destroySubscriptions() {
    if (this.authChangedSubscription) {
      this.authChangedSubscription.unsubscribe();
    }
    if (this.windowSizeChangedSubscription) {
      this.windowSizeChangedSubscription.unsubscribe();
    }
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    if (this.formStatusChangeSubscription) {
      this.formStatusChangeSubscription.unsubscribe();
    }
    if (this.windowResizedSubscription) {
      this.windowResizedSubscription.unsubscribe();
    }
    if (this.orientationChangedSubscription) {
      this.orientationChangedSubscription.unsubscribe();
    }
    if (this.windowMouseClickSubscription) {
      this.windowMouseClickSubscription.unsubscribe();
    }
    if (this.receivedMessageSubscription) {
      this.receivedMessageSubscription.unsubscribe();
    }
    if (this.windowStorageChangedSubscription) {
      this.windowStorageChangedSubscription.unsubscribe();
    }
  }
  getQueryParams(key: string) {
    if (this.queryParams) {
      if (this.queryParams[key]) {
        return this.queryParams[key];
      } else {
        if (this.queryParams.queryParams) {
          if (this.queryParams.queryParams[key]) {
            return this.queryParams.queryParams[key];
          }
        }
        if (this.queryParams.queryParamMap) {
          if (this.queryParams.queryParamMap[key]) {
            return this.queryParams.queryParamMap[key];
          }
        }
        if (this.queryParams.fragment) {
          let fragments = this.queryParams.fragment.split("&");
          if (fragments && fragments.length && !this.queryParams.fragments) {
            this.queryParams.fragments = {};
            fragments.forEach((x: any) => {
              let subFragment = x.split("=");
              if (subFragment && subFragment.length) {
                let theKey = "";
                let theValue = "";
                for (var i = 0; i < subFragment.length; i++) {
                  if (i === 0) {
                    theKey = subFragment[i];
                  } else {
                    if (theValue) {
                      theValue = theValue + "=" + subFragment[i];
                    } else {
                      theValue = subFragment[i];
                    }
                  }
                }
                this.queryParams.fragments[theKey] = theValue;
              }
            });
          }
          if (this.queryParams.fragments) {
            if (this.queryParams.fragments[key]) {
              return this.queryParams.fragments[key];
            }
          }
        }
      }
    }
    return null;
  }
  isCurrentPage(currentPagePath: string) {
    return this.appCtx.isCurrentPage(currentPagePath);
  }
  gotoHomePage() {
    this.sharedSvc.navigate(["/"]);
  }
  getRandomStart(range: number): number {
    let numberRange = 100 * range;
    return this.utilSvc.getRandomInt(numberRange);
  }
  checkAuthentications(componentName: string = "") {
    return new Promise((resolve, reject) => {
      try {
        this.appCtx.apiSvc.checkAuthenticated(componentName).subscribe(authenticatedUser => {
          if (authenticatedUser) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      } catch (e) {
        resolve(false);
      }
    });
  }
  onClickSignin(event: Event) {
    try {
      this.isShowSigninDialog = true;
      //this.ReturnUri = location.href;
      //this.sharedSvc.navigateByUrl("/signin?return=" + encodeURIComponent(this.replaceHref(location.href)));
    } catch (ex) {
      console.error(ex);
    }
  }
  onSignInDialogReturnEvent(value: boolean) {
    try {
      this.isShowSigninDialog = false;
      this.IsTopMenuView = false;
      let that = this;
      if (that.delayLoadInitTimer) {
        clearTimeout(that.delayLoadInitTimer);
      }
      that.delayLoadInitTimer = undefined;
      this.delayLoadInitTimer = setTimeout(function () {
        if (that.delayLoadInitTimer) {
          clearTimeout(that.delayLoadInitTimer);
        }
        that.delayLoadInitTimer = undefined;
        that.appCtx.navigateOnAuthenticated();
      }, 100);
    } catch (ex) {
      console.error(ex);
    }
  }
  onSignInDialogCancelEvent(value: boolean) {
    try {
      this.isShowSigninDialog = false;
      this.IsTopMenuView = false;
    } catch (ex) {
      console.error(ex);
    }
  }
  onClickSignout() {
    this.IsTopMenuView = false;
    if (this.appCtx.Authenticated) {
      this.signOut();
    } else {
      this.appCtx.credentialCleanUp();
      this.gotoHomePage();
    }
  }
  public signOut() {
    try {
      this.appCtx.apiSvc.signOut().subscribe((signoutResult: any) => {
        this.appCtx.credentialCleanUp();
        this.appCtx.navigateOnLogout();
      }, (error: any) => {
        console.error(error);
        this.appCtx.credentialCleanUp();
        this.gotoHomePage();
      });
    } catch (ex) {
      console.error(ex);
      this.appCtx.credentialCleanUp();
      this.gotoHomePage();
    }
  }
  public onClickSwitchEnvironment() {
    this.IsTopMenuView = false;
    if (this.appCtx.Authenticated) {
      let reqEnvironmentCode = this.appCtx.IsProd ? Constants.SystemEnvironment.Test : Constants.SystemEnvironment.Production;
      this.authSvc.setEnvironmentCode(reqEnvironmentCode).subscribe((result: any) => {
        if (result.IsSuccess && result.Data && this.domSvc.UserInfo) {
          let that = this;
          this.domSvc.CurrentEnvironmentCode = "";
          this.delayLoadInitTimer = setTimeout(function () {
            if (that.delayLoadInitTimer) {
              clearTimeout(that.delayLoadInitTimer);
            }
            that.delayLoadInitTimer = undefined;
            let userInfo = that.domSvc.UserInfo;
            userInfo.CurrentEnvironmentCode = reqEnvironmentCode;
            that.domSvc.UserInfo = userInfo;
            that.appCtx.iAppCtx.Subjects.onAuthenticationChanged.next(true);
            let href = document.location.href.toLowerCase();
            if (href.indexOf("https://arl.org.au/member") >= 0 && that.appCtx.currrentEnvironmentCode?.toLowerCase() == Constants.SystemEnvironment.Test.toLowerCase()) {
              document.location.replace("https://test.arl.org.au/member/sso?token=" + encodeURIComponent(result.Data));
            } else {
              if (href.indexOf("https://test.arl.org.au/member") >= 0 && that.appCtx.currrentEnvironmentCode?.toLowerCase() == Constants.SystemEnvironment.Production.toLowerCase()) {
                document.location.replace("https://arl.org.au/member/sso?token=" + encodeURIComponent(result.Data));
              }
            }
          }, 100);
        } else {
          this.sharedSvc.showError("An error occured while changing the environment. Please try again later.");
        }
      }, (error: any) => {
        console.error(error);
        this.sharedSvc.showError("An error occured while changing the environment. Please try again later.");
      });;
    } else {
      this.appCtx.credentialCleanUp();
      this.gotoHomePage();
    }
  }
  public getARLHomePageMenu(): MenuItem[] {
    let that = this;
    return [
      { label: "About ARL", routerLink: "/about", replaceUrl: false },
      {
        label: "Consumer",
        items: [
          { label: "Your actions matter", routerLink: "/your-actions-matter", replaceUrl: false },
          { label: "Your recycling guide", command: function () { that.sharedSvc.navigate(["/"]); }, routerLink: "/", replaceUrl: false },
          { label: "Check locally logo", routerLink: "/check-locally-logo", replaceUrl: false },
        ]
      }, {
        label: "Business",
        items: [
          { label: "ARL for business", routerLink: "/arl-for-business", replaceUrl: false },
          { label: "Join the ARL", routerLink: "/how-to-join", replaceUrl: false },
          { label: "ARL Marketplace", url: "https://www.arlmarketplace.org.au/", replaceUrl: false },
          {
            label: "Governance",
            items: [
              { label: "Why it's important", routerLink: "/why-its-important", replaceUrl: false },
              { label: "Get involved", routerLink: "/get-involved", replaceUrl: false },
              { label: "Update logs", routerLink: "/update-logs", replaceUrl: false },
              { label: "Resources", routerLink: "/resources", replaceUrl: false }
            ]
          }]
      }
    ];
  }
  public getConfigurationPageMenu() {
    if (this.appCtx.Authenticated && (this.isSA || this.isAdmin)) {
      return [
        { label: "Test ANZPAC Membership Form", url: this.baseURL + "/previewpage/anzpacmembership?flowName=SF_ANZPAC_Membership_Form", replaceUrl: false },
        { label: "Home", routerLink: "/admin/configure/home", replaceUrl: false, visible: (this.isSA || this.isAdmin) },
        { label: "User", routerLink: "/admin/configure/user", replaceUrl: false, visible: (this.isSA || this.isAdmin) },
        { label: "Organisation", routerLink: "/admin/configure/organisation", replaceUrl: false, visible: (this.isSA || this.isAdmin) },
        { label: "Domain Value", routerLink: "/admin/configure/domain", replaceUrl: false, visible: (this.isSA || this.isAdmin) },
        { label: "Page Content", routerLink: "/admin/configure/pagecontent", replaceUrl: false, visible: (this.isSA || this.isAdmin) },
        { label: "System Resources", routerLink: "/admin/configure/sysresource", replaceUrl: false, visible: (this.isSA || this.isAdmin) },
        { label: "ARL Program", routerLink: "/admin/configure/arlprogram", replaceUrl: false, visible: (this.isSA || this.isAdmin || this.isSupportUser || this.isUser) },
        { label: "ARL Submission", routerLink: "/admin/configure/arlsubmission", replaceUrl: false, visible: (this.isSA || this.isAdmin || this.isSupportUser || this.isUser) },
        { label: "PREP Update Log", routerLink: "/admin/configure/arlupdatelog", replaceUrl: false, visible: (this.isSA || this.isAdmin || this.isSupportUser || this.isUser) },
        { label: "ARL Resources", routerLink: "/admin/configure/arlresource", replaceUrl: false, visible: (this.isSA || this.isAdmin || this.isSupportUser || this.isUser) }
      ];
    } else {
      return [];
    }
  } public getMemberPageMenu() {
    if (this.appCtx.Authenticated && (this.isSA || this.isAdmin || this.isMemberAdmin || this.isMemberUser)) {
      return [
        { label: "About", routerLink: "/member/about", replaceUrl: false, visible: true },
        { label: "Packaging Specification", routerLink: "/member/a/packspec", replaceUrl: false, visible: (this.isSA || this.isAdmin || this.isMemberAdmin || this.isMemberUser) },
        { label: "Export Packaging Specification", routerLink: "/member/a/exportpackspec", replaceUrl: false, visible: (this.isSA || this.isAdmin || this.isMemberAdmin || this.isMemberUser) },
        { label: "Import Packaging Specification", routerLink: "/member/a/importpackspec", replaceUrl: false, visible: (this.isSA || this.isAdmin || this.isMemberAdmin || this.isMemberUser) }
      ];
    } else {
      return [];
    }
  }
}
